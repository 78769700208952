/* Font Sizes */
export const fonts = {
  fontStrong: 'bold',
  fontLvl0: '10px',
  fontLvl1: '11px',
  fontLvl2: '12px',
  fontLvl3: '13px',
  fontLvl4: '14px',
  fontLvl5: '15px',
  fontLvl8: '18px',
  fontLvl9: '22px',
};
