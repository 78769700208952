/* Colors */
export const colors = {
  softGrey: '#cccccc',
  lightGrey: '#666666',
  darkGrey: '#333333',
  smoothGrey: '#f6f6f6',
  faintGrey: '#eaeaea',
  ghostGrey: '#f2f2f2',
  white: '#ffffff',
  black: '#000000',
  darkYellow: '#fbb100',
  purple1: '#D4BDDB',
  green: '#33ad73',
  purple2: '#BC9AC7',
  purple3: '#9B7BA6',
  red: '#e34f45',
  greenBackground: '#e0f3ea',
};
